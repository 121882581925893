<template>
  <div class="card">
    <div class="card-header">{{ strat['title'] }}</div>
    <div class="card-desc">{{ strat['desc'] }}</div>
    <div class="card-body">
    </div>
    <button
      class="activate-button"
      @click="activate"
      :disabled="withinTradingHours"
    >
    <!-- <button
      class="activate-button"
      @click="activate"
    > -->
      Activate
    </button>
  </div>
</template>

<script>
import SymbolInput from '../common/SymbolInput.vue';

export default {
  name: 'InactiveStrat',
  components: { SymbolInput },
  props: {
    strat: {
      required: true
    },
    account_id: {
      required: true
    }
  },
  data() {
    return {
      alerts: {} // Displays any success/error messages
    };
  },
  computed: {
    withinTradingHours() {
      const nowESTString = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });
      const nowEST = new Date(nowESTString);
      const hour = nowEST.getHours();    // 0-23
      const minute = nowEST.getMinutes(); // 0-59
      const nowInMinutes = hour * 60 + minute;
      // Trading window from 9:20 (9*60+20 = 560) to 16:00 (16*60 = 960)
      const start = 9 * 60 + 20; // 560
      const end = 16 * 60;       // 960

      return nowInMinutes >= start && nowInMinutes < end;
    }
  },
  methods: {
    async activate() {
      if (this.withinTradingHours) {
        alert("Cannot activate during 9:20 AM - 4:00 PM EST.");
        return;
      }

      const options = {
        method: 'POST',
        body: JSON.stringify({
          account_id: this.account_id,
          id: this.strat['id']
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin' // Sends express-session credentials with request
      };
      try {
        const response = await fetch('/api/strats/activate', options);
      } catch (error) {
        console.error('API request error:', error);
      }

      this.$emit('update');
    },
  }
};
</script>

<style scoped>
.card {
  flex-shrink: 0;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 475px;
}

.card-header {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.card-desc {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.activate-button {
  cursor: pointer;
  margin-top: 10px;
  padding: 12px 16px;
  font-size: 16px;
  color: white;
  background-color: #76EE59;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Inter', sans-serif;
}

.activate-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
